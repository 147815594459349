<template>
    <v-container class="pt-0" id="gamified-alerts">
        <!-- Date Picker inside v-menu -->
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" max-width="330" min-width="330">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field filled hide-details="" v-model="dates" label="Seleccionar un rango de fechas" readonly
                    v-bind="attrs" v-on="on" prepend-inner-icon="mdi-calendar"></v-text-field>
            </template>
            <v-date-picker v-model="dates" range width="100%" scrollable :min="dates.length > 0 ? dates[0] : null">
                <v-spacer></v-spacer>
                <v-btn text color="primary darken-1" @click="dates = []">Cancelar</v-btn>
                <v-btn text color="primary darken-1" @click="menu = false; fetchAlertsForDate()">Confirmar</v-btn>
            </v-date-picker>
        </v-menu>

        <v-autocomplete v-model="userID" :items="computedUsers" label="Seleccionar usuario (opcional)" filled
            single-line dense hide-details clearable @change="fetchAlertsForDate()"></v-autocomplete>

        <v-card>
            <v-card-title>
                <v-icon left>mdi-trophy</v-icon>
                Ranking de Usuarios
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar usuario"
                            prepend-inner-icon="mdi-magnify"
                            filled
                            dense
                            hide-details
                            class="mb-4"
                        ></v-text-field>
                        <v-data-table 
                            :headers="rankingHeaders" 
                            :items="rankedUsers" 
                            :search="search"
                            class="elevation-1" 
                            mobile-breakpoint="0" 
                            :loading="loading" 
                            @click:row="showUserAlerts">
                            <template #[`item.rank`]="{ item }">
                                <v-chip :color="getColorForRank(item.rank)" v-if="item.rank > 3">
                                    {{ item.rank }}
                                </v-chip>
                                <v-icon v-else-if="item.rank == 1"
                                    :color="getColorForRank(item.rank)">mdi-trophy</v-icon>
                                <v-icon v-else-if="item.rank == 2"
                                    :color="getColorForRank(item.rank)">mdi-medal</v-icon>
                                <v-icon v-else-if="item.rank == 3"
                                    :color="getColorForRank(item.rank)">mdi-medal</v-icon>
                            </template>
                            <template #[`item.user_id`]="{ item }">
                                {{ item.displayName }}
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="container podium primary darken-1 rounded-lg pa-4" v-if="!loading">
                            <div class="podium__item text-center ">
                                <v-avatar size="40" class="mb-2">
                                    <UserImage small :user="podiumUsers[1]" :useCivilId="true" />
                                </v-avatar>
                                <p class="podium__city ">
                                    {{ podiumUsers[1]?.displayName || podiumUsers[1]?.user_id }}</p>
                                <div class="podium__rank second">#2</div>
                            </div>
                            <div class="podium__item text-center">
                                <v-avatar size="40" class="mb-2">
                                    <UserImage small :user="podiumUsers[0]" :useCivilId="true" />
                                </v-avatar>
                                <p class="podium__city">{{ podiumUsers[0]?.displayName || podiumUsers[0]?.user_id }}</p>
                                <div class="podium__rank first">
                                    #
                                    <svg class="podium__number" viewBox="0 0 27.476 75.03"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g transform="matrix(1, 0, 0, 1, 214.957736, -43.117417)">
                                            <path class="st8"
                                                d="M -198.928 43.419 C -200.528 47.919 -203.528 51.819 -207.828 55.219 C -210.528 57.319 -213.028 58.819 -215.428 60.019 L -215.428 72.819 C -210.328 70.619 -205.628 67.819 -201.628 64.119 L -201.628 117.219 L -187.528 117.219 L -187.528 43.419 L -198.928 43.419 L -198.928 43.419 Z"
                                                style="fill: #000;" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div class="podium__item text-center">
                                <v-avatar size="40">
                                    <UserImage small :user="podiumUsers[2]" :useCivilId="true" />
                                </v-avatar>
                                <p class="podium__city">{{ podiumUsers[2]?.displayName || podiumUsers[2]?.user_id }}</p>
                                <div class="podium__rank third">#3</div>
                            </div>
                        </div>
                        <v-skeleton-loader type="image" v-else></v-skeleton-loader>
                    </v-col>

                    <v-col cols="12">
                        <v-alert type="info" dismissible text>
                            <strong>Nota:</strong> Los usuarios se clasifican según la cantidad de puntos obtenidos por
                            sus acciones.

                            Las posibles acciones que generan puntos son:<br />
                            <ul>
                                <li>Check-in sin reserva: 1 punto</li>
                                <li>Reserva no utilizada: -1 puntos</li>
                                <li>Licencia de usuario pausada: 0 puntos</li>
                                <li>Check-in tardío: -0.2 puntos</li>
                                <li>Check-in temprano: 1 punto</li>
                                <li>Check-in temprano después de la hora de inicio: 1 punto</li>
                                <li>Check-in: 1 punto</li>
                            </ul>





                            <br>
                            <br>
                            <strong>Top 3:</strong> Los primeros 3 usuarios con más puntos.
                            <br>

                            <strong>Top 10%:</strong> Los usuarios que se encuentran en el 10% superior de la tabla de
                            clasificación.
                            <br>

                            <strong>Top 20%:</strong> Los usuarios que se encuentran en el 20% superior de la tabla de

                            clasificación.
                            <br>

                            <strong>Top 30%:</strong> Los usuarios que se encuentran en el 30% superior de la tabla de

                            clasificación.

                        </v-alert>
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-card-title class="headline">{{ selectedUser.displayName || selectedUser.user_id }}</v-card-title>
                <v-card-text>
                    <v-timeline>
                        <v-timeline-item v-for="alert in userAlerts" :key="alert.id"
                            :color="getColorForAlert(alert.type)" :icon="getIconForAlert(alert.type)">
                            <v-card>
                                <v-card-title>{{ translateAlertType(alert.type) }}</v-card-title>
                                <v-card-subtitle>{{ getFormattedDate(alert.date) }}</v-card-subtitle>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import moment from 'moment';

import UserImage from '@/components/profile/UserImage.vue';

export default {
    components: {
        UserImage
    },
    data() {
        return {
            search: '',
            userID: null,
            dates: [],
            alerts: [],
            users: {},
            menu: false,
            loading: false,
            dialog: false,
            selectedUser: {},
            userAlerts: [],
            rankingHeaders: [
                { text: 'Ranking', value: 'rank', align: 'center', width: '100px' },
                { text: 'Usuario', value: 'displayName' },
                { text: 'Puntos', value: 'points', align: 'end' }
            ],
            rankedUsers: [],
            podiumUsers: [],
            top10Percent: 0,
            top20Percent: 0,
            top30Percent: 0
        };
    },
    methods: {
        async fetchAlertsForDate() {
            try {
                this.alerts = [];
                this.users = {};
                this.loading = true;

                const db = getFirestore();
                let start, end;

                if (this.dates.length == 2) {
                    start = new Date(this.dates[0] + " 00:00:00");
                    end = new Date(this.dates[1] + " 23:59:59");
                } else {
                    let date = moment();
                    let startOfMonth = moment().startOf('month');
                    start = new Date(startOfMonth.format('YYYY-MM-DD') + " 00:00:00");
                    end = new Date(date.format('YYYY-MM-DD') + " 23:59:59");
                    this.dates = [startOfMonth.format('YYYY-MM-DD'), date.format('YYYY-MM-DD')];
                }

                let q;
                if (this.userID) {
                    q = query(
                        collection(db, 'alerts'),
                        where('date', '>=', start),
                        where('date', '<=', end),
                        where('user_id', '==', this.userID)
                    );
                } else {
                    q = query(
                        collection(db, 'alerts'),
                        where('date', '>=', start),
                        where('date', '<=', end)
                    );
                }

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(docSnapshot => {
                    const alertData = docSnapshot.data();
                    //console.log('Alert data:', alertData); // Debug log
                    console.log(alertData.user_id ? 'true' : 'false');
                    this.alerts.push({
                        
                        ...alertData,
                        id: docSnapshot.id,
                    });
                });

                // Filter invalid alerts
                this.alerts = this.alerts.filter(alert => {
                    const isValid = alert.user_id 

                    if (!isValid) {
                        debugger
                        //console.log(`${alert.type} ${moment(new Date(alert.date.seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')} `, alert);
                    }
                    return isValid;
                });

                await this.fetchUserDisplayNames();
                this.processRankedUsers();
                this.loading = false;
            } catch (error) {
                console.error('Error in fetchAlertsForDate:', error);
                this.$notify({
                    group: 'feedback',
                    title: 'Error',
                    text: 'Error al cargar los datos',
                    type: 'error'
                });
                this.loading = false;
            }
        },
        async fetchUserDisplayNames() {
            const db = getFirestore();
            let userIDs = [...new Set(this.alerts.map(alert => alert.user_id))];

            // Filter out invalid user IDs
            userIDs = userIDs.filter(userID => userID && typeof userID === 'string');

            const userDocs = await Promise.all(
                userIDs.map(async userID => {
                    try {
                        const userDoc = await getDoc(doc(db, 'users', userID.trim()));
                        const userData = userDoc.data();
                        return {
                            userID,
                            userDoc: userData
                        };
                    } catch (error) {
                        console.error(`Error fetching user ${userID}:`, error);
                        return {
                            userID,
                            userDoc: null
                        };
                    }
                })
            );

            userDocs.forEach(({ userID, userDoc }) => {
                if (userDoc && userDoc.displayName) {
                    this.users[userID] = userDoc.displayName;
                } else {
                    this.users[userID] = `Usuario ${userID}`;
                }
            });
        },
        getColorForRank(rank) {
            // first three ranks have gold, silver and bronze colors
            if (rank == 1) {
                return '#d4af37';
            }
            if (rank == 2) {
                return '#c0c0c0';
            }
            if (rank == 3) {
                return '#cd7f32';
            }

            if (rank <= this.top10Percent) {
                return 'primary';
            }
            if (rank <= this.top20Percent) {
                return 'primary darken-2';
            }
            if (rank <= this.top30Percent) {
                return 'primary darken-3';
            } else {
                return 'primary darken-4';
            }
        },
        getPointsForAlertType(type) {
            switch (type) {
                case 'checkin_no_reservation':
                    return 0.5;
                case 'reservation_not_used':
                    return -1;
                case 'user_license_paused':
                    return 0;
                case 'late_checkin':
                    return -0.2;
                case 'early_checkin':
                    return 1;
                case 'early_checkin_after_start':
                    return 1;
                case 'checkin':
                    return 1;
                default:
                    return 0;
            }
        },
        translateAlertType(type) {
            switch (type) {
                case 'checkin_no_reservation':
                    return 'Check-in sin reserva';
                case 'reservation_not_used':
                    return 'Reserva no utilizada';
                case 'user_license_paused':
                    return 'Licencia de usuario pausada';
                case 'late_checkin':
                    return 'Check-in tardío';
                case 'early_checkin':
                    return 'Check-in antes de hora';
                case 'early_checkin_after_start':
                    return 'Check-in al inicio';
                case 'checkin':
                    return 'Check-in';
                default:
                    return 'Alerta desconocida';
            }
        },
        processRankedUsers() {
            const userPoints = {};

            this.alerts.forEach(alert => {
                const { user_id, type } = alert;
                if (!user_id || typeof user_id !== 'string') return;

                if (!userPoints[user_id]) {
                    userPoints[user_id] = 0;
                }
                userPoints[user_id] += this.getPointsForAlertType(type);
            });

            const sortedUsers = Object.entries(userPoints)
                .map(([user_id, points]) => ({
                    user_id,
                    points,
                    displayName: this.users[user_id] || `Usuario ${user_id}`
                }))
                .filter(user => user.points !== 0)
                .sort((a, b) => b.points - a.points);

            // Calculate clusters
            const totalUsers = sortedUsers.length;
            this.top10Percent = Math.ceil(totalUsers * 0.03);
            this.top20Percent = Math.ceil(totalUsers * 0.2);
            this.top30Percent = Math.ceil(totalUsers * 0.3);

            this.rankedUsers = sortedUsers.map((user, index) => ({
                ...user,
                rank: index + 1
            }));

            this.podiumUsers = this.rankedUsers.slice(0, 3);
        },
        async showUserAlerts(user) {
            this.selectedUser = user;
            this.userAlerts = this.alerts.filter(alert => alert.user_id === user.user_id);
            this.dialog = true;
        },
        getColorForAlert(type) {
            switch (type) {
                case 'checkin_no_reservation':
                    return 'warning';
                case 'reservation_not_used':
                    return 'red';
                case 'user_license_paused':
                    return 'blue';
                case 'late_checkin':
                    return 'warning';
                case 'early_checkin':
                    return 'green';
                case 'early_checkin_after_start':
                    return 'teal';
                case 'checkin':
                    return 'primary';
                default:
                    return 'grey';
            }
        },
        getIconForAlert(type) {
            switch (type) {
                case 'checkin_no_reservation':
                    return 'mdi-account-cancel';
                case 'reservation_not_used':
                    return 'mdi-calendar-remove';
                case 'user_license_paused':
                    return 'mdi-account-off';
                case 'late_checkin':
                    return 'mdi-clock-alert';
                case 'early_checkin':
                    return 'mdi-clock-start';
                case 'early_checkin_after_start':
                    return 'mdi-clock-check';
                case 'checkin':
                    return 'mdi-check';
                default:
                    return 'mdi-alert';
            }
        },
        getFormattedDate(date) {
            return moment(date.seconds * 1000).format('DD/MM/YYYY HH:mm');
        }
    },
    computed: {
        computedUsers() {
            let users = this.alerts.map(alert => alert.user_id);
            return [...new Set(users)];
        }
    },
    mounted() {
        this.fetchAlertsForDate();
    }
};
</script>

<style>
#gamified-alerts .v-chip {
    margin: 2px;
}

.container.podium {
    display: flex;
    justify-content: space-around;
    align-items: end;
    margin-top: 20px;
}

.podium__item {
    width: 33.3%;
}

.podium__rank {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    color: #000;
}

.podium__city {
    text-align: center;
    padding: 0 .5rem;
    font-weight: bold;
    color: #000;
    font-size: 1.1rem;
}

.podium__number {
    width: 27px;
    height: 75px;
}

.podium .first {
    min-height: 300px;
    background: #d4af37;
}

.podium .second {
    min-height: 200px;
    background: #c0c0c0;
}

.podium .third {
    min-height: 100px;
    background: #cd7f32;
}
</style>
